<template>
  <layout :title="$route.name">
    <v-tabs background-color="#333" dark :color="$theme.primary" v-model="tabs">
      <v-tab class="fonte">Meus dados</v-tab>
      <v-tab class="fonte">Meus Endereços</v-tab>
    </v-tabs>
    <Form class="pa-3" v-if="tabs === 0" />
    <MainAddress class="pa-3" v-if="tabs === 1" />
    <ModalViewAddress />
  </layout>
</template>

<script>
import { mapGetters } from "vuex";
import Form from './components/Form'
import MainAddress from '@/apps/9#addresses/Main'
import ModalViewAddress from '@/apps/9#addresses/components/ModalViewAddress'
export default {
  data() {
    return {
      tabs:0
    }
  },
  components: {
    Form,
    MainAddress,
    ModalViewAddress
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  }
};
</script>
